import request from '@/utils/request'


// 查询用户积分变动记录列表
export function listScoreLog(query) {
  return request({
    url: '/user/user-score-log/list',
    method: 'get',
    params: query
  })
}

// 查询用户积分变动记录分页
export function pageScoreLog(query) {
  return request({
    url: '/user/user-score-log/page',
    method: 'get',
    params: query
  })
}

// 查询用户积分变动记录详细
export function getScoreLog(data) {
  return request({
    url: '/user/user-score-log/detail',
    method: 'get',
    params: data
  })
}

// 新增用户积分变动记录
export function addScoreLog(data) {
  return request({
    url: '/user/user-score-log/add',
    method: 'post',
    data: data
  })
}

// 修改用户积分变动记录
export function updateScoreLog(data) {
  return request({
    url: '/user/user-score-log/edit',
    method: 'post',
    data: data
  })
}

// 删除用户积分变动记录
export function delScoreLog(data) {
  return request({
    url: '/user/user-score-log/delete',
    method: 'post',
    data: data
  })
}
